
const userModule = {
    namespaced:true,
    state:()=>({
        user: {},
        construction:{},
        photo:null
    }),
    getters: {
        getUser: (state) => { return state.user },
        getPhoto: (state) => { return state.photo },
        getConstruction:(state)=>{return  state.construction}
    },
    mutations: {
        setUser(state, user) { state.user = user },
        setConstruction(state, construction) { state.construction = construction },
        setPhoto(state, photo) { state.photo = photo },
    },

    actions: {

    }

}

export default userModule