const BudgetService = {
   namespaced: true,

   state: () => ({
      dialogNewComposition: false
   }),

   getters: {
              
   },

   mutations: {
       
   },

}


export default BudgetService