const  CompositionGroup = {
   namespaced: true,
   state: () => ({
      compositionGroupId:null
   }),
   getters: {
      getCompositionGroupId:(state)=>{return state.compositionGroupId}
   },
   mutations: {
     setCompositionGroupId: (state, id)=>{ state.compositionGroupId = id } 
   },
}

export default CompositionGroup