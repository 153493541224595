import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from'./vuex'
import axios from '@/axios.js'
import VueAxios from 'vue-axios'
import Toast from './plugins/toastification'
import Alert from './plugins/sweetalert'
import "./plugins/vuetify-money.js";

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

new Vue({
  
  Alert,
  Toast,
  axios,
  VueAxios,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
