const ReportInput = {
    namespaced: true,

    state: () => ({
        stage: null,
    }),

    getters: {
        getStage: (state) => {

            return state.stage;
        },
    },

    actions:{

    },

    mutations: {
        setStage: (state, stage) => {
            state.stage = stage;
        },

    },
};

export default ReportInput;
