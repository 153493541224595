import axios from "@/axios"


export default{
    
    saveResetPassword(form) {
        return axios.post('reset_password', form)
    },

    sendLinkResetPassword(form){
        return axios.post('send_password_reset_link',form)
    }


}