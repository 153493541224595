const ReportStage = {
    namespaced: true,

    state: () => ({
        costId: null,
        stage: null
    }),

    getters: {
        getCostId: (state) => {
            return state.costId
        },
        getStage: (state) => {
            return state.stage
        }

    },

    mutations: {
        setCostId: (state, id) => {
            state.costId = id
        },
        setStage: (state, id) => {
            state.stage = id
        }
    },


}

export default ReportStage
