// import axios from '@/axios'
const ReportComposition = {
    namespaced: true,

    state: () => ({
        costId: null,
        stage: null
    }),

    getters: {

        getStage: (state) => {
             return state.stage;
        },

        // getCostId: (state) => {
        //     return state.costId;
        // },

        // getName: (state) => {
        //     return state.costName;
        // },
    },

    mutations: {

        setStage: (state, stage) => {
            state.stage = stage;
        },

        // setCostName: (state, id) => {
        //    state.costName = id;
        // },
    },
    actions:{
        
        
        

    }
};

export default ReportComposition;
