const Composition = {
    namespaced: true,

    state: () => ({
        inputs: [],
        compositions: [],
        sumValueInputs: 0,
        sumTax: 0,
        sumTotal:0,
        // copyOriginInputs:[],
        // copyOriginCompositions:[],

    }),

    getters: {
        getInputs: (state) => { return state.inputs },

        getCompositions: (state) => { return state.compositions },
        getSumValueInputs(state){return state.sumValueInputs},
        getSumTax(state){return state.sumTax},
        getSumTotal(state){return state.sumTotal}



    },

    mutations: {

        //inputs
        setInputs(state, inputs) {


            inputs.map((input)=>{
                input.order = parseInt(input.input_group.code + input.full_code ) //colocar propriedade order
            })

            //ordena pela propriedade order
            inputs.sort((a,b)=>{
                if(a.order < b.order) return -1;
                if(a.order > b.order) return 1;
                return 0;
            })


            state.inputs = inputs
        },

        //compositions
        setCompositions(state, compositions) {
            
            //colocar propriedade order
            compositions.map((composition)=>{
                composition.order = parseInt(composition.composition_group.code + composition.code )
            })

            //ordena pela propriedade order
            compositions.sort((a,b)=>{
                if(a.order < b.order) return -1;
                if(a.order > b.order) return 1;
                return 0;
            })

            state.compositions = compositions
        },


        calcSum(state){
        //soma total de insumos
        var sumInputs = state.inputs.reduce((acc, cor) => {
            return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
        }, 0)

        //TAX INPUTS
        var sumInputTax = state.inputs.reduce((acc, item) => {
            return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
        }, 0)

        let sumCompositions = state.compositions.reduce((acc, cor) => {
            return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
        }, 0)

        let sumCompositionTax = state.compositions.reduce((acc, item) => {
            return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
        }, 0)

        state.sumValueInputs = sumCompositions + sumInputs
        state.sumTax = sumInputTax + sumCompositionTax
        state.sumTotal = state.sumValueInputs + state.sumTax
        },

        saveQuantityComposition(state,item){
            let composition = state.compositions.find((composition)=>{return composition.id == item.id})
            const index = state.compositions.indexOf(composition)
            Object.assign(state.compositions[index],item)

            //soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax
        },

        storeQuantity(state,item){


            let input = state.inputs.find((input)=>{return input.id == item.id})
            const index = state.inputs.indexOf(input);
            Object.assign(state.inputs[index],item)
            //soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax

        },



        deleteInput(state, input) {
            let inputs = state.inputs

            inputs.splice(input, 1)
            state.input = inputs

            //soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax
        },

        addInput(state, input) {

            var inputs = state.inputs

            inputs.push(input)
            state.inputs = inputs

            //soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax
        },

        setSumValueInputs(state) { state.sumValueInputs = "" },



        deleteComposition(state, composition) {
            let compositions = state.compositions
            compositions.splice(composition, 1)
            state.compositions = compositions

            ///soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax
        },

        addComposition(state, composition) {
            var compositions = state.compositions
            compositions.push(composition)
            state.compositions = compositions

            ///soma total de insumos
            var sumInputs = state.inputs.reduce((acc, cor) => {
                return acc + (parseFloat(cor.value) * parseFloat(cor.pivot.quantity))
            }, 0)

            //TAX INPUTS
            var sumInputTax = state.inputs.reduce((acc, item) => {
                return acc + ( (parseFloat(item.tax)/100) * (parseFloat(item.value) * parseFloat(item.pivot.quantity)) )
            }, 0)

            let sumCompositions = state.compositions.reduce((acc, cor) => {
                return acc + (parseFloat(cor.total) * parseFloat(cor.pivot.quantity))
            }, 0)

            let sumCompositionTax = state.compositions.reduce((acc, item) => {
                return acc + (parseFloat(item.tax) * parseFloat(item.pivot.quantity))
            }, 0)

            state.sumValueInputs = sumCompositions + sumInputs
            state.sumTax = sumInputTax + sumCompositionTax
            state.sumTotal = state.sumValueInputs + state.sumTax
        },
    },

}


export default Composition
