<template>
  <v-container>
    <div class="title_table mt-6">
      <div style="float: left">INFORMAÇÕES DO CONTRATO DE TRABALHO</div>
    </div>
    <v-card>
      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headersEmploymentContract"
        :items="itemsEmploymentContract"
        elevation="2"
        :items-per-page="20"
      >
        <template v-slot:item.value="props">
          <!-- verifica se é editavel -->
          <div v-if="props.item.editable == true">
            <v-edit-dialog :return-value.sync="props.item.value" @save="save">
              <div class="editable">{{ formatMoney(props.item.value) }}</div>
              <template v-slot:input>
                <vuetify-money
                  v-model="props.item.value"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{
                    locale: 'pt-BR',
                    suffix: '%',
                    length: 11,
                    precision: 2,
                  }"
                />
              </template>
            </v-edit-dialog>
          </div>
          <div v-else>
            <div>{{ formatMoney(props.item.value) }}</div>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <div class="title_table mt-6">
      <div style="float: left">GRUPO A - ENCARGOS SOCIAIS BÁSICOS</div>
      <div style="float: right">{{ formatMoney(totalGroupA) }}%</div>
    </div>
    <v-card>
      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headersGroupA"
        :items="itemsGroupA"
        elevation="2"
      >
        <template v-slot:item.value="props">
          <v-edit-dialog :return-value.sync="props.item.value" @save="save">
            <div class="editable">
              {{ formatMoney(props.item.value) }}
            </div>
            <template v-slot:input>
              <vuetify-money
                v-model="props.item.value"
                :clearable="true"
                valueWhenIsEmpty="0"
                :options="{
                  locale: 'pt-BR',
                  suffix: '%',
                  length: 11,
                  precision: 2,
                }"
              />
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-card>

    <div class="title_table mt-6">
      <div style="float: left">GRUPO B - ENCARGOS TRABALHISTAS</div>
      <div style="float: right">{{ formatMoney(totalGroupB) }}%</div>
    </div>
    <v-card>
      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headersGroupB"
        :items="itemsGroupB"
        elevation="2"
      >
        <template v-slot:item.value="props">
          {{ formatMoney(props.item.value) }}
        </template>
      </v-data-table>
    </v-card>

    <div class="title_table mt-6">
      <div style="float: left">GRUPO C - ENCARGOS INDENIZATÓRIOS</div>
      <div style="float: right">{{ formatMoney(totalGroupC) }}%</div>
    </div>
    <v-card>
      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headersGroupC"
        :items="itemsGroupC"
        elevation="2"
      >
        <template v-slot:item.value="props">
          {{ formatMoney(props.item.value) }}
        </template>
      </v-data-table>
    </v-card>

    <div class="title_table mt-6">
      <div style="float: left">GRUPO D - REINCIDÊNCIAS</div>
      <div style="float: right">{{ formatMoney(totalGroupD) }}%</div>
    </div>
    <v-card>
      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headersGroupD"
        :items="itemsGroupD"
        elevation="2"
      >
        <template v-slot:item.value="props">
          {{ formatMoney(props.item.value) }}
        </template>
      </v-data-table>
    </v-card>
    <div class="title_table mt-6">
      <div style="float: left">TOTAL</div>
      <div style="float: right">{{ formatMoney(totalGroup) }}%</div>
    </div>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
    
  </v-container>
</template>

<script>
import service from "./service.js";
export default {
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      loadingTables: false,
      totalGroupA: 0,
      totalGroupB: 0,
      totalGroupC: 0,
      totalGroupD: 0,
      totalGroup: 0,
      headersEmploymentContract: [
        { text: "", align: "start", value: "name", sortable: false },
        { text: "Unidade", align: "end", value: "unity", sortable: false },
        { text: "", width: 3, align: "end", value: "value", sortable: false },
      ],
      itemsEmploymentContract: [
        {
          group: "group0",
          name: "DIAS DO ANO",
          unity: "DIAS",
          editable: true,
          value: 0,
        },
        {
          group: "group0",
          name: "DIAS TRABALHADOS NO ANO",
          editable: false,
          unity: "FOR",
          value: 0,
        },
        {
          group: "group0",
          name: "DIAS NÃO TRABALHADOS DO ANO",
          editable: false,
          unity: "FOR",
          value: 0,
        },
        {
          group: "group0",
          name: "DURAÇÃO DO CONTRATO DE TRABALHO",
          editable: true,
          unity: "MESES",
          value: 0,
        },
        {
          group: "group0",
          name: "TAXA DE DEMISSÃO POR JUSTA CAUSA",
          editable: true,
          unity: "%",
          value: 0,
        },
        {
          group: "group0",
          name: "AVISO PRÉVIO NA RESCISÃO",
          editable: false,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "INCIDÊNCIA DA INDENIZAÇÃO ADICIONAL (30 DIAS DO DISSÍDIO) ",
          editable: true,
          unity: "%",
          value: 0,
        },
        {
          group: "group0",
          name: "Férias Gozadas",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Descanso Semanal Remunerado",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Feriados",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Auxílio Enfermidade",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Acidente de trabalho",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Licença Paternidade",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Falta Justificada",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "Dias de Chuva",
          editable: true,
          unity: "",
          value: 0,
        },
        {
          group: "group0",
          name: "DIAS RECEBIDOS SEM TRABALHO",
          editable: true,
          unity: "",
          value: 0,
        },
        {
          group: "group0",
          name: "13o Salário",
          editable: true,
          unity: "DIAS",
          value: 0,
        },
        {
          group: "group0",
          name: "1/3 de férias",
          editable: false,
          unity: "DIAS",
          value: 0,
        },
      ],
      headersGroupA: [
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", align: "start", value: "name", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "value",
          sortable: false,
        },
      ],
      itemsGroupA: [
        { group: "groupA", item: "A1", name: "INSS", value: 0 },
        { group: "groupA", item: "A2", name: "FGTS", value: 0 },
        { group: "groupA", item: "A3", name: "Salário educação", value: 0 },
        { group: "groupA", item: "A4", name: "SESI", value: 0 },
        { group: "groupA", item: "A5", name: "SENAI", value: 0 },
        { group: "groupA", item: "A6", name: "INCRA", value: 0 },
        { group: "groupA", item: "A7", name: "SEBRAE", value: 0 },
        {
          group: "groupA",
          item: "A8",
          name: "Seguro contra acidentes de trabalho",
          value: 0,
        },
        { group: "groupA", item: "A9", name: "SECONCI", value: 0 },
      ],
      headersGroupB: [
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", align: "start", value: "name", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "value",
          sortable: false,
        },
      ],
      itemsGroupB: [
        { group: "groupB", item: "B1", name: "Férias Gozadas", value: 0 },
        {
          group: "groupB",
          item: "B2",
          name: "Descanso Semanal Remunerado",
          value: 0,
        },
        { group: "groupB", item: "B3", name: "Feriados", value: 0 },
        { group: "groupB", item: "B4", name: "Auxílio Enfermidade", value: 0 },
        { group: "groupB", item: "B5", name: "Acidente de trabalho", value: 0 },
        { group: "groupB", item: "B6", name: "Licença Paternidade", value: 0 },
        { group: "groupB", item: "B7", name: "Falta Justificada", value: 0 },
        { group: "groupB", item: "B8", name: "Dias de Chuva", value: 0 },
        { group: "groupB", item: "B9", name: "13o Salário", value: 0 },
      ],
      headersGroupC: [
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", align: "start", value: "name", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "value",
          sortable: false,
        },
      ],
      itemsGroupC: [
        {
          group: "groupC",
          item: "C1",
          name: "Aviso prévio indenizado",
          value: 0,
        },
        {
          group: "groupC",
          item: "C2",
          name: "Aviso prévio trabalhado",
          value: 0,
        },
        {
          group: "groupC",
          item: "C3",
          name: "Férias indenizadas + 1/3",
          value: 0,
        },
        {
          group: "groupC",
          item: "C4",
          name: "Depósito por despedida injusta 50% sobre  [A2 + (A2 +B)]",
          value: 0,
        },

        {
          group: "groupC",
          item: "C5",
          name: "Indenização adicional",
          value: 0,
        },
      ],
      headersGroupD: [
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", align: "start", value: "name", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "value",
          sortable: false,
        },
      ],
      itemsGroupD: [
        {
          group: "groupD",
          item: "D1",
          name: "Incidências de A sobre B",
          value: 0,
        },
        {
          group: "groupD",
          item: "D2",
          name: "Incidência de Férias sobre o Aviso Prévio",
          value: 0,
        },
        {
          group: "groupD",
          item: "D3",
          name: "Incidência do 13o Salário sobre o Aviso Prévio",
          value: 0,
        },
        {
          group: "groupD",
          item: "D4",
          name: "Incidência do FGTS sobre o Aviso Prévio",
          value: 0,
        },
      ],
    };
  },
  created() {this.initialize()},
  methods: {
    initialize() {
      this.loadingTables = true;

      service
        .getAll()
        .then((response) => {
          this.loadingTables = false;
          if (response.data.length != 0) {
                   
            let itemsGroup0 = response.data.filter((item) => {
              return item.group == "group0";
            });
            this.itemsEmploymentContract[0] = Object.assign(this.itemsEmploymentContract[0] , itemsGroup0[0])
            this.itemsEmploymentContract[1] = Object.assign(this.itemsEmploymentContract[1] , itemsGroup0[1])
            this.itemsEmploymentContract[2] = Object.assign(this.itemsEmploymentContract[2] , itemsGroup0[2])
            this.itemsEmploymentContract[3] = Object.assign(this.itemsEmploymentContract[3] , itemsGroup0[3])
            this.itemsEmploymentContract[4] = Object.assign(this.itemsEmploymentContract[4] , itemsGroup0[4])
            this.itemsEmploymentContract[5] = Object.assign(this.itemsEmploymentContract[5] , itemsGroup0[5])
            this.itemsEmploymentContract[6] = Object.assign(this.itemsEmploymentContract[6] , itemsGroup0[6])
            this.itemsEmploymentContract[7] = Object.assign(this.itemsEmploymentContract[7] , itemsGroup0[7])
            this.itemsEmploymentContract[8] = Object.assign(this.itemsEmploymentContract[8] , itemsGroup0[8])
            this.itemsEmploymentContract[9] = Object.assign(this.itemsEmploymentContract[9] , itemsGroup0[9])
            this.itemsEmploymentContract[10] = Object.assign(this.itemsEmploymentContract[10] , itemsGroup0[10])
            this.itemsEmploymentContract[11] = Object.assign(this.itemsEmploymentContract[11] , itemsGroup0[11])
            this.itemsEmploymentContract[12] = Object.assign(this.itemsEmploymentContract[12] , itemsGroup0[12])
            this.itemsEmploymentContract[13] = Object.assign(this.itemsEmploymentContract[13] , itemsGroup0[13])
            this.itemsEmploymentContract[14] = Object.assign(this.itemsEmploymentContract[14] , itemsGroup0[14])
            this.itemsEmploymentContract[15] = Object.assign(this.itemsEmploymentContract[15] , itemsGroup0[15])
            this.itemsEmploymentContract[16] = Object.assign(this.itemsEmploymentContract[16] , itemsGroup0[16])
            this.itemsEmploymentContract[17] = Object.assign(this.itemsEmploymentContract[17] , itemsGroup0[17])
        
            this.itemsGroupA = response.data.filter((item) => {
              return item.group == "groupA";
            });
            this.itemsGroupB = response.data.filter((item) => {
              return item.group == "groupB";
            });
            this.itemsGroupC = response.data.filter((item) => {
              return item.group == "groupC";
            });
            this.itemsGroupD = response.data.filter((item) => {
              return item.group == "groupD";
            });
            this.calculator();
            this.calculator();
          }
        })
        .catch(() => {
          this.loadingTables = false;
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Não foi possível atualizar os dados";
        });
    },
    
    save() {
      setTimeout(() => {
        //2x garantir o caculo de toda a folha
        this.calculator();
        this.calculator();
        this.saveAll();
      }, 50);
    },
    calculator() {
      //caucular dias trabalhados
      this.calcWorkedDays();

      //cacular dias nao trabalahdo
      this.calcDaysNotWorked();

      //aviso previo na recisao
      this.calcNoticePriorTermination();

      //calcular dias de ferias
      this.calcAThirdOfVacations();

      // grupo B porcentagem  Férias Gozadas
      this.percentageVacationTaken();

      //grupo b Descanso Semanal Remunerado
      this.percentageWeeklyPaidRest();

      //grupo b porcentagem Feriados
      this.percentageHolidays();

      //grupo b percentage auxilio enfermidade
      this.percentageIllnessAssistance();

      //grupo b procentagem acidente de trabalho
      this.percentageWorkAccident();

      //grupo b porcentagem lincença parternidade
      this.percentagePaternityLeave();

      //grupo b porcentagem falta justificada
      this.percentageJustifiedAbsence();

      //groupb dias de chuva
      this.percentageRainyDays();

      //grupo b porcentagem decimo terceiro
      this.percentageThirteenthSalary();

      //grupo c Aviso prévio indenizado
      this.percentageIndemnifiedPriorNotice();

      //grupo c Férias indenizadas + 1/3
      this.percentageOfIndemnifiedVacations();

      //grupo c Depósito por despedida injusta 50% sobre  [A2 + (A2 +B)]
      this.percentageUnfairFarewell();

      //grupo c additionalIndemnity
      this.additionalIndemnity();

      //grupo d Incidências de A sobre B
      this.Incidences();

      //grupo d Incidência de Férias sobre o Aviso Prévio
      this.holidaysOnNotice();

      //Incidência do 13o Salário sobre o Aviso Prévio
      this.salaryOnEarlyWarning();

      //Incidência do FGTS sobre o Aviso Prévio
      this.fgtsIncidence();

      //cacular o valor total do grupo A
      this.totalGroupA = this.itemsGroupA.reduce((acc, cor) => {
        return acc + this.parseNumber(cor.value);
      }, 0);
      //cacular o valor total do grupo B
      this.totalGroupB = this.itemsGroupB.reduce((acc, cor) => {
        return acc + this.parseNumber(cor.value);
      }, 0);
      //cacular o valor total do grupo C
      this.totalGroupC = this.itemsGroupC.reduce((acc, cor) => {
        return acc + this.parseNumber(cor.value);
      }, 0);
      //cacular o valor total do grupo D
      this.totalGroupD = this.itemsGroupD.reduce((acc, cor) => {
        return acc + this.parseNumber(cor.value);
      }, 0);
      //total geral
      this.totalGroup =
        this.totalGroupA +
        this.totalGroupB +
        this.totalGroupC +
        this.totalGroupD;
    },
    calcWorkedDays() {
      let soma =
        this.parseNumber(this.itemsEmploymentContract[7].value) +
        this.parseNumber(this.itemsEmploymentContract[8].value) +
        this.parseNumber(this.itemsEmploymentContract[9].value) +
        this.parseNumber(this.itemsEmploymentContract[10].value) +
        this.parseNumber(this.itemsEmploymentContract[11].value) +
        this.parseNumber(this.itemsEmploymentContract[12].value) +
        this.parseNumber(this.itemsEmploymentContract[13].value);

      let daysYear = this.itemsEmploymentContract[0].value;
      this.itemsEmploymentContract[1].value = daysYear - soma;
    },
    calcDaysNotWorked() {
      let soma =
        this.parseNumber(this.itemsEmploymentContract[7].value) +
        this.parseNumber(this.itemsEmploymentContract[8].value) +
        this.parseNumber(this.itemsEmploymentContract[9].value) +
        this.parseNumber(this.itemsEmploymentContract[10].value) +
        this.parseNumber(this.itemsEmploymentContract[11].value) +
        this.parseNumber(this.itemsEmploymentContract[12].value) +
        this.parseNumber(this.itemsEmploymentContract[13].value);

      this.itemsEmploymentContract[2].value = soma;
    },
    calcNoticePriorTermination() {
      this.itemsEmploymentContract[5].value =
        33 *
        parseInt(this.parseNumber(this.itemsEmploymentContract[3].value) / 12);
    },
    calcAThirdOfVacations() {
      this.itemsEmploymentContract[17].value =
        this.parseNumber(this.itemsEmploymentContract[16].value) / 3;
    },
    percentageVacationTaken() {
      let a =
        this.parseNumber(this.itemsEmploymentContract[7].value) +
        this.parseNumber(this.itemsEmploymentContract[17].value);

      let b = this.parseNumber(this.itemsEmploymentContract[1].value);
      let c = 12 / this.parseNumber(this.itemsEmploymentContract[3].value);

      this.itemsGroupB[0].value = (a / b) * c * 100;
    },
    percentageWeeklyPaidRest() {
      this.itemsGroupB[1].value =
        (this.parseNumber(this.itemsEmploymentContract[8].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageHolidays() {
      this.itemsGroupB[2].value =
        (this.parseNumber(this.itemsEmploymentContract[9].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageIllnessAssistance() {
      this.itemsGroupB[3].value =
        (this.parseNumber(this.itemsEmploymentContract[10].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageWorkAccident() {
      this.itemsGroupB[4].value =
        (this.parseNumber(this.itemsEmploymentContract[11].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentagePaternityLeave() {
      this.itemsGroupB[5].value =
        (this.parseNumber(this.itemsEmploymentContract[12].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageJustifiedAbsence() {
      this.itemsGroupB[6].value =
        (this.parseNumber(this.itemsEmploymentContract[13].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageRainyDays() {
      this.itemsGroupB[7].value =
        (this.parseNumber(this.itemsEmploymentContract[14].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageThirteenthSalary() {
      this.itemsGroupB[8].value =
        (this.parseNumber(this.itemsEmploymentContract[16].value) /
          this.parseNumber(this.itemsEmploymentContract[1].value)) *
        100;
    },
    percentageIndemnifiedPriorNotice() {
      let a =
        this.parseNumber(this.itemsEmploymentContract[5].value) /
        this.parseNumber(this.itemsEmploymentContract[1].value);

      let b = 12 / this.parseNumber(this.itemsEmploymentContract[3].value);

      this.itemsGroupC[0].value = a * b * 100;
    },
    percentageOfIndemnifiedVacations() {
      let vacation =
        this.parseNumber(this.itemsEmploymentContract[7].value) +
        this.parseNumber(this.itemsEmploymentContract[17].value);
      let workedDays = this.parseNumber(this.itemsEmploymentContract[1].value);
      let workContract = this.parseNumber(
        this.itemsEmploymentContract[3].value
      );

      let resut =
        ((vacation / workedDays) * (workContract - 12)) / workContract;
      this.itemsGroupC[2].value = resut * 100;
    },
    percentageUnfairFarewell() {
      let fgts = this.parseNumber(this.itemsGroupA[1].value) / 100;
      let layoffFee = this.parseNumber(this.itemsEmploymentContract[4].value) / 100;
      let result =  ((fgts)*(this.totalGroupB/100)+(fgts))* 0.5 * layoffFee    //((fgts + fgts) / 100) * (this.totalGroupB / 100) * layoffFee * 0.5;
      this.itemsGroupC[3].value = result * 100;
    },
    additionalIndemnity() {
      let workedDays =
        30 / this.parseNumber(this.itemsEmploymentContract[1].value);
      let employmentContract =
        12 / this.parseNumber(this.itemsEmploymentContract[3].value);
      let result =
        workedDays *
        employmentContract *
        this.parseNumber(this.itemsEmploymentContract[6].value);
      this.itemsGroupC[4].value = result;
    },
    Incidences() {
      let result =
        (this.parseNumber(this.totalGroupB) / 100) *
        (this.parseNumber(this.totalGroupA) / 100);
      this.itemsGroupD[0].value = result * 100;
    },
    holidaysOnNotice() {
      let result =
        (this.parseNumber(this.itemsGroupB[0].value) / 100) *
        (this.parseNumber(this.itemsGroupC[0].value) / 100);
      this.itemsGroupD[1].value = result * 100;
    },
    salaryOnEarlyWarning() {
      let result =
        (this.parseNumber(this.itemsGroupB[8].value) / 100) *
        (this.parseNumber(this.itemsGroupC[0].value) / 100);
      this.itemsGroupD[2].value = result * 100;
    },
    fgtsIncidence() {
      let result =
        (this.parseNumber(this.itemsGroupA[1].value) / 100) *
        (this.parseNumber(this.itemsGroupC[0].value) / 100);
      this.itemsGroupD[3].value = result * 100;
    },
    convertNumeric() {
      this.itemsGroupA.forEach((item) => {
        item.value = this.parseNumber(item.value);
      });
      this.itemsGroupB.forEach((item) => {
        item.value = this.parseNumber(item.value);
      });
      this.itemsGroupC.forEach((item) => {
        item.value = this.parseNumber(item.value);
      });
      this.itemsGroupD.forEach((item) => {
        item.value = this.parseNumber(item.value);
      });
    },
    saveAll() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Dados atualizados";
      this.convertNumeric();
      let social_charges = {
        itemsEmploymentContract: this.itemsEmploymentContract,
        itemsGroupA: this.itemsGroupA,
        itemsGroupB: this.itemsGroupB,
        itemsGroupC: this.itemsGroupC,
        itemsGroupD: this.itemsGroupD,
      };
      service
        .updateOrStore(social_charges)
        .then((response) => {
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
  },
};
</script>

<style scoped>
.title_table {
  width: 100%;
  height: 30px;
  background: #1976d2;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  padding-top: 3px;
}
.editable {
  color: #1976d2;
}
</style>
