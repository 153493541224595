import axios from "@/axios"


export default {

    logout() {
        return axios.post('auth/logout')
    },

    allNameConstructions() {
        return axios.get('organization/construction/all_name_constructions')
    },

    swapConstruction(construction) {
        return axios.get('organization/construction/swap_construction/' + construction)
    }

}