const InputGroup = {
   namespaced: true,

   state: () => ({
      InputGroupId:null
   }),

   getters: {
      getInputGroupId:(state)=>{return state.InputGroupId}
   },

   mutations: {
     setInputGroupId: (state, id)=>{ state.InputGroupId = id } 
   },

}


export default InputGroup