
const RegisterOrganizationUsers = {
    namespaced:true,
    state: ()=>({
        organization: {}
    }),
    getters: {
        getOrganization: (state) => { return state.organization }
    },

    mutations: {
        setOrganization(state, organization) { state.organization = organization }
    },
    actions: {

    }

}


export default RegisterOrganizationUsers