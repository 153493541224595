const Role = {
   namespaced: true,

   state: () => ({
      role:null
   }),

   getters: {
      getRole:(state)=>{return state.role}
   },

   mutations: {
     setRole: (state, role)=>{ state.role = role } 
   },

}

export default Role