import axios from "axios"

const route = '/physical_progress';

export default {
    namespaced: true,

    state: () => ({
        dialogNewComposition: false
    }),
    getters: {

    },
    mutations: {

    },
    actions: {
        index(context,data){
            let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage||10}&search=${data.search}`
            return axios.get(`${route}/physical_progress${pagination}`)
        },

        store(context,data){
            return axios.post(`${route}/physical_progress`,data)
        },
        update(context,data){
            return axios.put(`${route}/physical_progress/${data.physical_progress_id}`,data)
        },
        
        destroy(context,physicalProgress){
            return axios.delete(`${route}/physical_progress/${physicalProgress.id}`)
        },
        // CRIAR NOVO PERIODO
        newPeriod(constext, physical_progress ){
            return axios.post(`${route}/new_period`,physical_progress)
        },

        loadDataPhysicalProgress(context,physical_progress_id){
            return axios.get(`${route}/load_physical_progress/${physical_progress_id}`)
        },
       
        getServices(context,budget_id) {
            return axios.get(`${route}/get_services/${budget_id}`)
        },
        
        getCostSelect() {
            return axios.get(`${route}/get_cost_select`)
        },
        
        exportExcel(constext,physicalProgressId) {
            return axios.get(route + '/export_excel/'+physicalProgressId,{responseType: 'blob'})
        },
        
        // DETAILS PHYSICAL PROGRESS
        updateCreateSheetPhysicalProgress(context,data){
            return axios.post(`${route}/update_create_sheet/${data.physical_progress_item_id}`,data)
        },
        getLocals(context,data){

            return axios.post(`${route}/get_locals`,data)
        },
        loadContractor(context, composition_id ){
            return axios.get(`${route}/get_contractor_available/${composition_id}`)
        },
        // METERING CONTRACT
        updateCreateMeteringContract(context,data){
            return axios.post(`${route}/update_create_metering_contract`,data)
        },
        getMeteringContract(context,metering_id){
            return axios.get(`${route}/get_metering_contract/${metering_id}`)
        },


        



    }
}


