import Vue from "vue";
import Router from "vue-router";
import Layout from "@/pages/Layout/Layout.vue"
import Login from "@/pages/Login/Login.vue"
import ResetPassword from "@/pages/Login/ResetPassword.vue"
import Test from "@/pages/Test/Test.vue"
import guard from "./middleware/guard";

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/",
            name: "home",
            component: Login,
        },
        {
            path: "*",
            redirect: '/',
        },
        {
            path: "/reset_password/:token",
            name: "reset_password",
            component: ResetPassword,
        },

        {
            path: "/system",
            beforeEnter: guard.auth,
            name: "system",
            component: Layout,
            children: [
                { path: "test", name: "test", component: Test },
                { path: 'dashboard', component: () => import('./pages/System/Dashboard/Dashboard') },
                { path: 'register_organization', component: () => import('./pages/System/Admin/RegisterOrganization/RegisterOrganization') },
                { path: 'register_users_organization', component: () => import('./pages/System/Admin/RegisterOrganizationUsers/RegisterOrganizationUsers') },
                { path: 'profile', component: () => import('@/pages/System/Profile/Profile') },

                //Cadastro
                { path: 'register/construction', component: () => import('@/pages/System/Register/Construction/Construction') },
                { path: 'register/stage', component: () => import('@/pages/System/Register/Stage/Stage') },
                { path: 'register/composition_group', component: () => import('@/pages/System/Register/CompositionGroup/CompositionGroup') },
                { path: 'register/input', component: () => import('@/pages/System/Register/Input/Input') },
                { path: 'register/composition', component: () => import('@/pages/System/Register/Composition/Composition') },
                { path: 'register/input_group', component: () => import('@/pages/System/Register/InputGroup/InputGroup') },
                { path: 'register/additional_charges', component: () => import('@/pages/System/Register/AdditionalCharges/AdditionalCharges') },
                { path: 'register/social_charges', component: () => import('@/pages/System/Register/SocialCharges/SocialCharges') },

                //Orçamento
                { path: 'budget/service', component: () => import('@/pages/System/Budget/Service/Service.vue') },
                { path: 'budget/service_stages', component: () => import('@/pages/System/Budget/ServiceStages/ServiceStages.vue') },
                { path: 'budget/cost', component: () => import('@/pages/System/Budget/Cost/Cost') },
                { path: 'budget/forming_price_data', component: () => import('@/pages/System/Budget/FormingPriceData/FormingPriceData') },
                { path: 'budget/forming_price', component: () => import('@/pages/System/Budget/FormingPrice/FormingPrice') },

                // Avanço Fisico
                { path: 'physical_progress/physical_progress', component: () => import('@/pages/System/PhysicalProgress/PhysicalProgress/PhysicalProgress') },
                { path: 'physical_progress/edit_physical_progress', component: () => import('@/pages/System/PhysicalProgress/PhysicalProgress/_components/EditPhysicalProgress') },
                { path: 'physical_progress/edit_physical_progress/:physical_progress_id', component: () => import('@/pages/System/PhysicalProgress/PhysicalProgress/_components/EditPhysicalProgress') },
                {
                    path: 'physical_progress/edit_physical_progress/:physical_progress_id/:view',
                    component: () => import('@/pages/System/PhysicalProgress/PhysicalProgress/_components/EditPhysicalProgress')
                },
                { path: 'physical_progress/contractor', component: () => import('@/pages/System/PhysicalProgress/Contractor/Contractor') },
                { path: 'physical_progress/contractor_historic/:contractor_id', name:'contractor_historic', component: () => import('@/pages/System/PhysicalProgress/Contractor/_components/ContractorHistoric') },
                { path: 'physical_progress/order_service', component: () => import('@/pages/System/PhysicalProgress/OrderService/OrderService') },

                { path: 'physical_progress/metering', component: () => import('@/pages/System/PhysicalProgress/Metering/Metering') },
                { path: 'physical_progress/historic_metering', component: () => import('@/pages/System/PhysicalProgress/Metering/_components/HistoricMetering')},



                //Relatorios
                // { path: 'report/input_per_stage', component: () => import('@/pages/System/Report/InputPerStage/InputPerStage.vue') },
                // { path: 'report/input_per_stage/report', component: () => import('@/pages/System/Report/InputPerStage/ReportInputPerStage.vue') },

                //Relatorio de Etapa
                { path: 'report/stage', component: () => import('@/pages/System/Report/Stage/Stage.vue') },
                { path: 'report/stage/stages', component: () => import('@/pages/System/Report/Stage/_pages/Stages.vue') },
                { path: 'report/stage/stages/compositions/:stage_id', component: () => import('@/pages/System/Report/Stage/_pages/ReportStage.vue') },

                //Relatorio de Composicao
                { path: 'report/composition', component: () => import('@/pages/System/Report/Composition/Cost.vue') },
                { path: 'report/composition/stages/:cost_id', component: () => import('@/pages/System/Report/Composition/_pages/Stages.vue') },
                { path: 'report/composition/stages/compositions/:stage_id', component: () => import('@/pages/System/Report/Composition/_pages/ReportComposition.vue') },
                { path: 'report/composition/abc/compositions/:cost_id', component: () => import('@/pages/System/Report/Composition/_pages/ReportAbcComposition.vue') },

                //Relatorio de insumos
                { path: 'report/input', component: () => import('@/pages/System/Report/Input/Cost.vue') },
                { path: 'report/input/stages/:cost_id', component: () => import('@/pages/System/Report/Input/_pages/Stages.vue') },
                { path: 'report/input/stages/inputs/:cost_id/:stage_id', component: () => import('@/pages/System/Report/Input/_pages/ReportInput.vue') },
                { path: 'report/input/abc/inputs/:cost_id', component: () => import('@/pages/System/Report/Input/_pages/ReportAbcInput.vue') },
                { path: 'report/input/total/inputs/:cost_id', component: () => import('@/pages/System/Report/Input/_pages/ReportInputTotal.vue') },

                //System
                { path: 'system/role', component: () => import('@/pages/System/System/Role/Role.vue') },
                { path: 'system/permission', component: () => import('@/pages/System/System/Permission/Permission.vue') },

                //Developer
                { path: 'developer/permission', component: () => import('@/pages/System/Developer/Permission/Permission.vue') },
            ]
        },


    ]
})
