import axios from "axios"

const route = '/physical_progress';

export default {
    namespaced: true,

    state: () => ({
        dialogNewComposition: false
    }),
    getters: {

    },
    mutations: {

    },
    actions: {
        index(context,data){
            let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage||10}&search=${data.search}`
            return axios.get(`${route}/contractor${pagination}`)
        },
        getUpdateContractor(){
            return axios.get(`${route}/contractor/get_update_contractor`)
        },
        storeConfigIntegration(context, configIntegration ){
            return axios.post(`${route}/contractor/update_or_create_config_integration`,configIntegration)
        },
        loadConfigIntegration(){
            return axios.get(`${route}/contractor/get_config_integration_contractor`)
        },

        //Historico de empreiteiros
        getHistoric(context, data){
            let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage||10}&search=${data.search}`
            return axios.get(`${route}/contractor/historic/${data.contractor_id}${pagination}`)
        },

        //detalhes da medicao
        getContractSummary(context, contract_id){
          return axios.get(`${route}/contractor/historic/contract_summary/${contract_id}`)
        }


    }
}


