import Vue from 'vue'
import Vuex from 'vuex'
import RegisterOrganizationUsers from '@/pages/System/Admin/RegisterOrganizationUsers/store'
import Login from '@/pages/Login/store'
import Composition from '@/pages/System/Register/Composition/store'
import InputGroup from '@/pages/System/Register/InputGroup/store'
import CompositionGroup from '@/pages/System/Register/CompositionGroup/store'
import BudgetService from '@/pages/System/Budget/Service/store'
import Cost from '@/pages/System/Budget/Cost/store'
import FormingPrice from '@/pages/System/Budget/FormingPrice/store'
import Role from '@/pages/System/System/Role/store'
import VuexPersistence from 'vuex-persist'
//import ReportInputPerStage from '@/pages/System/Report/InputPerStage/store'
import ReportStage from '@/pages/System/Report/Stage/store'
import ReportComposition from '@/pages/System/Report/Composition/store'
import ReportInput from '@/pages/System/Report/Input/store'
import PhysicalProgress from '@/pages/System/PhysicalProgress/PhysicalProgress/store'
import Contractor from '@/pages/System/PhysicalProgress/Contractor/store'
import OrderService from '@/pages/System/PhysicalProgress/OrderService/store'
import Metering from './pages/System/PhysicalProgress/Metering/store'




Vue.use(Vuex)


const store = new Vuex.Store({
    modules: {
        RegisterOrganizationUsers,
        Login,
        Composition,
        InputGroup,
        CompositionGroup,
        BudgetService,
        FormingPrice,
        Cost,
        Role,
        ReportStage,
        ReportComposition,
        ReportInput,
        PhysicalProgress,
        Contractor,
        OrderService,
        Metering
    },
    plugins: [new VuexPersistence().plugin]

})

export { store }
