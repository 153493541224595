const  menu =  [
        {
            menu_name: "Dashboard",
            icon: "mdi-monitor-dashboard",
            link: "/system/dashboard",
        },
        {
            menu_name: "Cadastro",
            icon: "mdi-database-check",
            items: [
                {
                    title: "Obras",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/construction",
                },
                {
                    title: "Etapa",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/stage",
                },
                {
                    title: "Composição",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/composition_group",
                },
                {
                    title: "Insumos",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/input_group",
                },

                {
                    title: "Encargos Socias",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/social_charges",
                },
                {
                    title: "Encargos Complementares",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/register/additional_charges",
                },
            ],
        },
        {
            menu_name: "Orçamento",
            icon: "mdi-cash-multiple",
            items: [
                {
                    title: "Custos",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/budget/cost",
                },
                // {
                //   title: "Orçamento",
                //   icon: "mdi-checkbox-blank-circle-outline",
                //   link: "/system/budget/budget",
                // },
                // {
                //   title: "Formação de Preço",
                //   icon: "mdi-checkbox-blank-circle-outline",
                //   link: "/system/budget/forming_price_data",
                // },
                {
                    title: "Formação de Preço",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/budget/forming_price",
                },
            ],
        },
        {
            menu_name: "Medições",
            icon: "mdi-gauge",
            items: [
                {
                    title: "Empreiteiro",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/physical_progress/contractor",
                },
                {
                    title: "Ordens de Serviço",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/physical_progress/order_service",
                },
                {
                    title: "Avanço Fisíco",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/physical_progress/physical_progress",
                },
                {
                    title: "Medições",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/physical_progress/metering",
                },
                
            ],
        },

        {
            menu_name: "Relatórios",
            icon: "mdi-chart-areaspline",
            items: [
                {
                    title: "Etapas",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/report/stage",
                },
                {
                    title: "Composições",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/report/composition",
                },
                {
                    title: "Insumos",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/report/input",
                },
            ],
        },
        {
            menu_name: "Sistema",
            icon: "mdi-cog",
            items: [
                {
                    title: "Perfis",
                    icon: "mdi-checkbox-blank-circle-outline",
                    link: "/system/system/role",
                },
            ],
        },
    ]

export default menu
