<template>
  <v-app id="inspire">
    <Menu :drawer="drawer" />
    <!-- <v-toolbar-title>Application</v-toolbar-title>  -->
    <v-app-bar app color="accent-4" dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
     
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <h5>Obra: {{ NameConstruction }} <a @click="changeConstruction()"  style="font-size:15px">(Alterar)</a></h5>
        </v-toolbar-title>

      
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/system/profile">
            <v-list-item-title class="mr-2">Dados Pessoais</v-list-item-title>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item>
          <v-list-item @click="changeConstruction()">
            <v-list-item-title class="mr-2">Trocar Obra</v-list-item-title>
            <v-icon>mdi-forklift</v-icon>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title class="mr-2">Logout</v-list-item-title>
            <v-icon>mdi-logout</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- route view -->
    <v-main>
        <transition name="slide-fade">
              <router-view ></router-view>
      </transition>
       
    </v-main>

    <!-- dialog  -->
    <v-dialog v-model="dialogchangeConstruction" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Alterar Obra</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-spacer></v-spacer>
            <v-select
              label="Obras"
              v-model="construction"
              :items="itemsContructions"
              item-text="name"
              item-value="id"
            ></v-select>
            <v-spacer></v-spacer>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogchangeConstruction = false"
            >Fechar</v-btn
          >
          <v-btn color="primary" :disabled="loadingBtnApply" :loading="loadingBtnApply" @click="applyContruction">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Menu from "./components/Menu.vue";
import service from "./service";
export default {
  components: {
    Menu,
  },
  data() {
    return {
      loadingBtnApply:false,
      construction: {},
      itemsContructions: [],
      dialogchangeConstruction: false,
      drawer: true,
    };
  },
  computed: {
    ...mapGetters("Login", ["getConstruction"]),
    NameConstruction() {
      return this.getConstruction.name;
    },
  },
  methods: {
    ...mapMutations("Login", ["setConstruction"]),
    logout() {
      service.logout().then(() => {
        this.$router.push("/")
      }).catch(()=>{
        this.$router.push("/")
      })
    },
    changeConstruction() {
      this.dialogchangeConstruction = true;
      service.allNameConstructions().then((response) => {
        this.itemsContructions = response.data;
      });
    },
    applyContruction() {
      this.loadingBtnApply = true;
      service.swapConstruction(this.construction).then((response) => {
        this.setConstruction(response.data)
        localStorage.setItem("constructionid", response.data.id)
        this.$toast.success("Obra alterada com sucesso")
        this.loadingBtnApply = false
        this.dialogchangeConstruction = false
        this.$router.push('/system/dashboard')
      }).catch(()=>{
        this.loadingBtnApply = false
      })
    },
  },
};
</script>

<style>

/* *****************Estilo do titulo do modal **********/
.title_body{
  background:#1976D2;
  width:100%;
  height:45px;
  box-sizing:border-box;
  padding:5px;
  margin-bottom:20px
}

.text_title{
  color: white;
  font-size: 16px;
}

.btn_close{
  float:right;
}
/* **************************************************** */

/* Efeitos de transicao de troca de pagina */
.slide-fade-enter-active {
 transition: opacity 1.0s;
}
.slide-fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
/* *****************************************/
</style>