<template>
  <div data-app>
    <div class="login-wrap">
      <div class="login-html">
        <input
          id="tab-1"
          type="radio"
          name="tab"
          class="sign-in"
          checked
        /><label for="tab-1" class="tab">Recuperar senha</label>
        <input id="tab-2" type="radio" name="tab" class="sign-up" /><label
          for="tab-2"
          class="tab"
        ></label>

        <div class="login-form">
          <div class="sign-in-htm">
            <div class="group">
              <label for="user" class="label">Email</label>
              <input id="user" v-model="form.email" type="text" class="input" />
            </div>
            <div class="group">
              <label for="pass" class="label">Senha</label>
              <input
                v-model="form.password"
                id="pass"
                type="password"
                class="input"
                data-type="password"
              />
            </div>
            <div class="group">
              <label class="label">Confirmar senha</label>
              <input
                v-model="form.password_confirmation"
                type="password"
                class="input"
                data-type="password"
              />
            </div>
            <div class="group">
              <button :disabled="btn_disabled" @click="send()" class="button">
                Salvar
                <v-progress-circular
                  :width="1"
                  :size="20"
                  v-show="loading_rec"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
              </button>
            </div>
            <div class="hr"></div>
            <div class="foot-lnk">
              <!-- <input id="tab-2" type="radio" name="tab" class="sign-up" /><label  for="tab-2"  style="font-size:14" class="">Recuperar senha</label> -->
              <a href="/">Fazer login</a>
            </div>
          </div>

          <div class="sign-up-htm">
            <div class="group">
              <label for="pass" class="label">Email</label>
              <input id="pass" type="text" class="input" />
            </div>
            <div class="group">
              <input type="button" class="button" value="Recuperar senha" />
            </div>
            <div class="hr"></div>
            <div class="foot-lnk">
              <label for="tab-1">Login</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogResponse.dialog" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">{{ dialogResponse.msg }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="dialogResponse.dialog = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "./service";
export default {
  data() {
    return {
      dialogResponse: {
        dialog: false,
        msg: "",
      },
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        resetToken: "",
      },
      form_default:{
        email: "",
        password: "",
        password_confirmation: "",
        resetToken: "",
      },

      loading_rec: false,
      btn_disabled: false,
    };
  },
  methods: {
    send() {
      this.loading_rec = true;
      this.btn_disabled = true;
      service
        .saveResetPassword(this.form)
        .then((response) => {
          this.loading_rec = false;
          this.btn_disabled = false;
          this.dialogResponse.msg = response.data;
          this.dialogResponse.dialog = true;
          this.form = this.form_default
        })
        .catch((response) => {
          this.loading_rec = false;
          this.btn_disabled = false;
          let errors = response.response.data;

          for (var erro in errors) {
            this.dialogResponse.msg = errors[erro][0];
            this.dialogResponse.dialog = true;
            break;
          }
          //   for (var [key, value] of Object.entries(response.response.data)) {
          //     console.log(key + " " + value);
          //   }
        });
    },
  },
  created() {
    this.form.resetToken = this.$route.params.token;
  },
};
</script>