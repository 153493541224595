import axios from 'axios'
import Vue from 'vue'

axios.defaults.baseURL = process.env.VUE_APP_URL_API


axios.interceptors.request.use(function (config) {
    config.headers.Authorization = 'bearer ' + localStorage.getItem('token')
    config.headers.constructionid = localStorage.getItem('constructionid')
    return config
});

axios.interceptors.response.use(function (response) {

    return response;
}, function (error) {

    switch (error.response.status) {
        case 422:
            // intercepta erro 422
            var errors = error.response.data

            var html = "";
            for (let erro in errors) {
                html += "<p>" + errors[erro] + "</p>";
            }
            Vue.swal({
                confirmButtonColor: '#3085d6',
                position: "center",
                html: html,
                icon: "error",
                title: "Erro",
            });
            break
        case 401:
            // intercepta erro 401
            Vue.$toast.error("Não autorizado")
            break
        case 403:
            // intercepta erro 403
            Vue.$toast.error("Não autorizado")
            break
        case 500:
            // intercepta erro 500
            Vue.$toast.error("Erro no servidor")
            break
        case 423:
                // não faz nada esse erro será tratado na profuncao q chamou
                break

        default:
            Vue.$toast.error("Erro inesperado")
            break
    }

    return Promise.reject(error);
});

export default axios
