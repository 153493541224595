const  FormingPrice = {
   namespaced: true,
   state: () => ({
      formingPrice:{}      
   }),
   getters: {
    getFormingPrice:(state)=>{return state.formingPrice}
   },
   mutations: {
    setFormingPrice: (state, formingPrice)=>{ state.formingPrice = formingPrice} 
   },
}

export default FormingPrice
