import axios from "axios"
const route = 'budget/cost' 
const  Cost = {
   namespaced: true,

   state: () => ({
      costId:null
   }),

   getters: {
      getCostId:(state)=>{return state.costId}
   },

   mutations: {
     setCostId: (state, id)=>{ state.costId = id }
   },
   actions:{
      cloneCost(context ,cost_id){
         return axios.get(route+'/clone/'+cost_id)
      }
   }

}

export default Cost
